<template>
  <v-form
    v-if="externalStatuses && externalStatuses.length"
    ref="backwardStatusMappingForm"
    class="JiraStatusMappingForm__form-status"
  >
    <div
      v-for="externalStatus in backwardStatusMappingForm"
      :key="externalStatus.id"
      class="JiraStatusMappingForm__jira-statuses"
    >
      <div class="JiraStatusMappingForm__jira-status-external">
        <span>{{ externalStatus.name }}</span>
        <v-icon
          class="mr-10"
          color="#D3D6E2"
          v-text="'$arrow-forward-right'"
        />
      </div>
      <v-select
        :key="externalStatus.id"
        v-model="externalStatus.localStatus"
        :label="$t('integration.LocalStatus')"
        :items="localStatuses"
        :return-object="false"
        item-text="displayName"
        filled
        hide-details
        item-value="name"
        clearable
        clear-icon="mdi-close-circle"
        class="JiraStatusMappingForm__jira-status-local"
      >
        <template #selection="{ item: status }">
          <v-icon
            size="18"
            :color="status.color"
          >
            {{ status.icon }}
          </v-icon>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="ml-2"
                v-on="on"
              >{{ status.displayName.length > 13 ? status.displayName.slice(0, 12) + '...' : status.displayName }}</span>
            </template>
            <span
              style="white-space: pre-wrap"
              v-text="status.displayName"
            />
          </v-tooltip>
        </template>
        <template #item="{ item: status }">
          <v-icon
            size="18"
            :color="status.color"
          >
            {{ status.icon }}
          </v-icon>
          <span
            style="max-width: 300px; overflow: hidden"
            class="ml-2"
          >{{ status.displayName }}</span>
        </template>
      </v-select>
    </div>
  </v-form>
  <div
    v-else
    class="JiraStatusMappingForm__error"
  >
    <v-icon
      color="#FFC480"
      v-text="'mdi-alert-outline'"
    />
    <span>
      {{ $t('integration.StatusMappingUnavailableM') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'JiraStatusMappingForm',

  props: {
    backwardStatusMappingForm: { type: Array, default: () => [] },
    externalStatuses: { type: Array, default: () => [] },
    localStatuses: { type: Array, default: () => [] },
  },
}
</script>

<style scoped lang="sass">
.JiraStatusMappingForm
  &__jira
    &-logo
      margin-right: 14.33px
      height: 32px
      width: 89px

    &-status-local
      ::v-deep
        .v-icon.notranslate.v-icon--link.mdi.mdi-close-circle.theme--light.primary--text
          color: #8492A6 !important

    &-statuses
      display: flex
      justify-content: space-between
      margin-bottom: 40px

      &:last-child
        margin-bottom: 0

    &-status-external
      min-width: 50%
      max-width: 50%
      font-weight: 500
      display: flex
      align-items: center
      justify-content: space-between

  &__form
    height: 100%

    &-status
      max-height: 330px
</style>
