<template>
  <div class="IntegrationList">
    <div class="IntegrationList__header-row">
      <v-btn
        class="IntegrationList__title"
        text
        tile
        color="primary"
        :ripple="{ class: 'app-ripple' }"
        :height="48"
        @click="sortDesc = !sortDesc"
      >
        <span
          class="text--primary"
          v-text="$t('integration.Integrations')"
        />
        <v-icon
          class="IntegrationList__sort-icon"
          :class="{ 'IntegrationList__sort-icon--reversed': sortDesc }"
          size="11"
          v-text="'$sort'"
        />
      </v-btn>
    </div>

    <IntegrationListRow
      v-for="integration in sortedIntegrations"
      :key="integration.id"
      :project-id="projectId"
      :integration="integration"
      class="IntegrationList__row"
    />
  </div>
</template>

<script>
import moment from 'moment'
import * as R from 'ramda'

import IntegrationListRow from './IntegrationListRow'

export default {
  name: 'IntegrationList',

  components: {
    IntegrationListRow,
  },

  props: {
    projectId: { type: String, required: true },
    integrations: { type: Array, required: true },
  },

  data() {
    return {
      sortDesc: false,
    }
  },

  computed: {
    sortedIntegrations() {
      const { integrations, sortDesc } = this
      const getComparator = sortDesc ? R.descend : R.ascend
      return R.sortWith([
        getComparator(integration => integration.name?.toLowerCase?.()),
        getComparator(R.prop('name')),
        getComparator(integration => moment(integration.created).valueOf()),
      ], integrations)
    },
  },
}
</script>

<style lang="sass" scoped>
.IntegrationList
  background: white
  padding-bottom: 8px
  box-shadow: 0 1px 2px rgba(0, 0, 0, .2)
  border-radius: 8px

  &__header-row
    height: 48px
    display: flex
    align-items: stretch
    justify-content: center

  &__title
    display: flex
    align-items: center
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 20px
    border-bottom: 1px solid #F0F1F3

  &__sort-icon
    &--reversed
      transform: rotateZ(-180deg)
</style>
