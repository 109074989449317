<template>
  <v-dialog
    class="IntegrationDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :persistent="persistent"
    :max-width="520"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    @input="$emit('input', $event)"
  >
    <v-sheet
      class="IntegrationDialog__card"
      :min-height="267"
    >
      <PortalTarget name="ajax-bar" />

      <v-btn
        :disabled="persistent"
        icon
        absolute
        top
        right
        @click="$emit('input', false)"
      >
        <v-icon v-text="'mdi-close'" />
      </v-btn>

      <SetupJiraForm
        v-if="integrationType != null && integrationType.code === JIRA"
        ref="setupJiraForm"
        :key="`${integrationCode}-${integrationId}`"
        :project-id="projectId"
        :integration-id="integrationId"
        :saving.sync="persistent"
        :is-open="value"
        @close="$emit('input', false)"
      />

      <pre
        v-else-if="integrationType != null && integrationType.code === SLACK"
        class="pa-12"
        v-text="$t('integration.ComingSoon')"
      />
    </v-sheet>
  </v-dialog>
</template>

<script>
import { INTEGRATION, JIRA, SLACK } from '../constants'

import ajaxPortWhen from '../mixins/ajaxPortWhen'

import SetupJiraForm from './SetupJiraForm'

export default {
  name: 'IntegrationDialog',

  components: {
    SetupJiraForm,
  },

  mixins: [
    ajaxPortWhen('value'),
  ],

  inheritAttrs: false,

  props: {
    value: { type: Boolean, default: true }, // isOpen, v-model

    projectId: { type: String, default: null },
    // edit integration with this id:
    integrationId: { type: String, default: null },
    // or create integration with the following type:
    integrationCode: {
      type: String,
      default: null,
      validator: v => Object.keys(INTEGRATION).includes(v),
    },
  },

  data() {
    return {
      JIRA,
      SLACK,

      persistent: false, // :saving.sync="persistent"
    }
  },

  computed: {
    integration() {
      const { $store, integrationId } = this
      return $store.getters['integration/get'](integrationId)
    },

    integrationType() {
      const { integrationId, integration, integrationCode } = this
      return integrationId
        ? integration && integration.integrationType
        : integrationCode && INTEGRATION[integrationCode]
    },
  },

  watch: {
    value(isOpen) {
      if (isOpen) {
        this.$refs.setupJiraForm?.resetValidation?.() // eslint-disable-line
        this.$refs.setupSlackForm?.resetValidation?.() // eslint-disable-line
      }
    },

    integrationId() {
      this.persistent = false
    },
  },
}
</script>

<style lang="sass" scoped>
.IntegrationDialog
  &__card
    position: relative
</style>
