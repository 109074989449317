<template>
  <v-dialog
    class="IntegrationServerDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :persistent="persistent"
    :max-width="520"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    @input="$emit('input', $event)"
  >
    <v-sheet
      class="IntegrationServerDialog__card"
      :min-height="267"
    >
      <v-btn
        :disabled="persistent"
        icon
        absolute
        top
        right
        @click="$emit('input', false)"
      >
        <v-icon v-text="'mdi-close'" />
      </v-btn>

      <IntegrationServerForm
        ref="integrationServerForm"
        :saving.sync="persistent"
        :is-open="value"
        :server-id="serverId"
        @close="$emit('input', false)"
      />
    </v-sheet>
  </v-dialog>
</template>

<script>
import IntegrationServerForm from '@/components/IntegrationServerForm'

export default {
  name: 'IntegrationServerDialog',

  components: {
    IntegrationServerForm,
  },

  inheritAttrs: false,

  props: {
    value: { type: Boolean, default: true }, // isOpen, v-model

    serverId: { type: String, default: null },
  },

  data() {
    return {
      persistent: false, // :saving.sync="persistent"
    }
  },

  computed: {

  },

  watch: {
    value(isOpen) {
      if (isOpen) {
        this.$refs.integrationServerForm?.resetValidation?.() // eslint-disable-line
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.IntegrationServerDialog
  &__card
    position: relative
</style>
