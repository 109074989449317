<template>
  <tr
    class="IntegrationServersTableRow"
    @contextmenu.prevent="showContextMenu($event)"
  >
    <!-- First cell: project name -->
    <td class="IntegrationServersTableRow__link text-truncate">
      <span
        class="IntegrationServersTableRow__cell text-truncate pl-2"
        style="max-width: 640px"
        v-text="server.hostname"
      />
    </td>

    <td class="IntegrationServersTableRow__link text-truncate">
      <span
        class="IntegrationServersTableRow__cell text-truncate pl-2"
        style="max-width: 640px"
        v-text="server.name"
      />
    </td>

    <td class="IntegrationServersTableRow__link text-truncate">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            class="IntegrationServersTableRow__webhook"
            icon
            color="#2196F3"
            @click="copyToClipboard"
            v-on="on"
          >
            <v-icon>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>
        <span v-text="$t('integration.CopyWebhook')" />
      </v-tooltip>
      <v-btn
        text
        class="IntegrationServersTableRow__webhook"
        color="#2196F3"
        @click="regenerateToken"
      >
        <v-icon>
          mdi-autorenew
        </v-icon>
        <span class="ml-2">{{ $t('integration.RenewToken') }}</span>
      </v-btn>
    </td>

    <td class="IntegrationServersTableRow__link text-truncate">
      <span
        class="IntegrationServersTableRow__cell text-truncate pl-2"
        style="max-width: 640px"
        v-text="fmtDate(server.created)"
      />
    </td>

    <!-- Last cell: actions -->
    <td @click.stop>
      <div
        class="IntegrationServersTableRow__cell"
        style="justify-content: end"
      >
        <v-menu
          ref="serverContextMenu"
          v-model="serverMenu"
          :min-width="136"
          :position-x="positionX"
          :position-y="positionY"
          absolute
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="showContextMenu($event)"
            >
              <v-icon v-text="'mdi-dots-vertical'" />
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item
              replace
              :ripple="{ class: 'app-ripple' }"
              :to="editRoute"
            >
              <v-list-item-content>
                <v-list-item-title>{{ $t('integration.Edit') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              replace
              :ripple="{ class: 'app-ripple' }"
              @click="deleteIntegration"
            >
              <v-list-item-content>
                <v-list-item-title class="error--text">
                  {{ $t('integration.Delete') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </td>
  </tr>
</template>

<script>
import { fmtDate } from '@/helpers'
import copyToClipboard from 'copy-to-clipboard'

export default {
  name: 'IntegrationServersTableRow',

  props: {
    server: { type: Object, default: null },
  },

  data: () => ({
    serverMenu: false,
    positionX: 100,
    positionY: 100,
  }),

  computed: {
    editRoute() {
      return {
        name: 'IntegrationServers',
        query: { action: 'edit', serverId: this.server.id },
      }
    },

    fullServerName() {
      const { server } = this
      const hostPort = location.protocol + '//' + location.host + '/api'

      return hostPort + server.webhook
    },
  },

  methods: {
    fmtDate,

    showContextMenu(event) {
      this.serverMenu = true
      this.positionX = event.clientX
      this.positionY = event.clientY
    },

    async deleteIntegration() {
      const { $store, server: { id: serverId } } = this

      await $store.dispatch('integration/getIntegrationServerDetails', { serverId }).then(details => {
        $store.commit('integration/setIntegrationServerDetails', { serverId, details })
      })
      const integrations = this.server?.details?.integrations || null

      const subtitle = integrations?.length
        ? this.$t('integration.ServerWithConnectionsDeleteM', { integrationsNumber: integrations.length })
        : this.$t('integration.ActionImmediateM')

      const agreed = await $store.dispatch('confirm/openDialog', {
        title: this.$t('integration.DeleteServerQ'),
        subtitle,
        consentLabel: this.$t('integration.Delete'),
        consentProps: { color: 'error', depressed: true },
      })

      if (agreed) $store.dispatch('integration/deleteIntegrationServer', { serverId })
    },

    copyToClipboard() {
      copyToClipboard(this.fullServerName)
      this.$store.commit('$snackbar/setMessage', {
        message: this.$t('integration.WebhookCopiedM'),
      })
    },

    regenerateToken() {
      const { $store, server: { id: serverId } } = this
      $store.dispatch('integration/regenerateToken', { serverId }).finally(() => {
        this.$store.commit('$snackbar/setMessage', {
          message: this.$t('integration.TokenRenewed'),
        })
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.IntegrationServersTableRow
  &__webhook
    font-weight: 400 !important

  td
    border-bottom: none !important

  a
    color: inherit
    text-decoration: none

  &__link
    cursor: pointer

  &__cell
    display: flex
    align-items: center
</style>
