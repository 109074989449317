<template>
  <div class="Steppers">
    <div
      v-for="step in numberOfStep"
      :key="step"
      class="Steppers__step"
      :class="{ active: activeStep === step }"
      :style="style(step)"
    />
  </div>
</template>

<script>
export default {
  name: 'Steppers',

  props: {
    numberOfStep: { type: Number, required: true },
    color: { type: String, default: '#EDF0FA' },
    activeItemColor: { type: String, default: '#A6FFD3' },
    activeStep: { type: Number, default: 1 },
    margin: { type: String, default: '0 8px 0 0' },
    height: { type: Number, default: 6 },
    borderRadius: { type: Number, default: 4 },
  },

  methods: {
    style(currentStep) {
      const { activeStep, color, activeItemColor, margin, numberOfStep, height, borderRadius } = this
      const style = {}

      if (currentStep <= activeStep) style.backgroundColor = activeItemColor
      if (currentStep > activeStep) style.backgroundColor = color
      if (currentStep !== numberOfStep) style.margin = margin
      style.height = height + 'px'
      style.borderRadius = borderRadius + 'px'

      return style
    },
  },
}
</script>

<style scoped lang="sass">
  .Steppers
    display: flex
    align-items: center
    justify-content: space-between

    &__step
      width: 100%
</style>
