<template>
  <AppBarLayout
    class="IntegrationServersAppBar"
    v-bind="$attrs"
  >
    <template #header>
      {{ $t('integration.JiraServers') }}
    </template>

    <template #actions>
      <v-btn
        color="primary"
        depressed
        replace
        :to="dialogRoute"
      >
        <v-icon
          class="mr-2"
          v-text="'mdi-plus'"
        />
        {{ $t('integration.JiraServer') }}
      </v-btn>
    </template>
  </AppBarLayout>
</template>

<script>
import AppBarLayout from '../layouts/AppBarLayout'

export default {
  name: 'IntegrationServersAppBar',

  components: {
    AppBarLayout,
  },

  inheritAttrs: false,

  computed: {
    dialogRoute() {
      return {
        name: 'IntegrationServers',
        query: { action: 'create' },
      }
    },
  },
}
</script>
