<template>
  <div class="IntegrationServers">
    <IntegrationServersAppBar />

    <IntegrationServersTable :integration-servers="Object.values(integrationServers)" />

    <IntegrationServerDialog
      :value="integrationDialog"
      :server-id="serverId"
      @input="!$event && closeIntegrationDialog()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { replaceRoute } from '../helpers'

import IntegrationServersAppBar from '../components/IntegrationServersAppBar'
import IntegrationServersTable from '../components/IntegrationServersTable'
import IntegrationServerDialog from '../components/IntegrationServerDialog'

export default {
  name: 'IntegrationServers',

  metaInfo() {
    return {
      title: this.$store.getters.title(this.$t('integration.IntegrationServers')),
    }
  },

  components: {
    IntegrationServersAppBar,
    IntegrationServersTable,
    IntegrationServerDialog,
  },

  props: {
    integrationDialog: { type: Boolean, default: false },
    serverId: { type: String, default: null },
  },

  computed: {
    ...mapGetters({
      integrationServers: 'integration/integrationServers',
    }),

    baseRoute() {
      return {
        name: 'IntegrationServers',
      }
    },
  },

  created() {
    this.fetchIntegrationServers()
  },

  methods: {
    fetchIntegrationServers() {
      this.$store.dispatch('integration/getIntegrationServers')
    },

    closeIntegrationDialog() {
      replaceRoute(this.$router, this.baseRoute)
    },
  },
}
</script>

<style lang="sass" scoped>
.IntegrationServers
  $y-padding: 64px
  max-width: 1152px + $y-padding * 2
  margin: 0 auto
  padding: 88px $y-padding

  &__title
    margin-bottom: 34px
</style>
