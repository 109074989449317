<template>
  <component
    :is="(!licInfo || disabled) ? 'div' : 'router-link'"
    v-ripple="!!licInfo && !disabled && { class: 'app-ripple' }"
    class="IntegrationCard"
    :class="{ 'IntegrationCard--disabled': !licInfo || disabled }"
    :style="cardStyle"
    v-bind="!licInfo || disabled ? {} : { to: dialogRoute, replace: true }"
    @click="onClick"
  />
</template>

<script>
import { INTEGRATION } from '../constants'

export default {
  name: 'IntegrationCard',

  props: {
    projectId: {
      type: String,
      required: true,
    },
    integrationCode: {
      type: String,
      required: true,
      validator: v => Object.keys(INTEGRATION).includes(v),
    },
  },

  computed: {
    licInfo() { return this.$store.state.license.information },

    integrationType() {
      const { integrationCode } = this
      return INTEGRATION[integrationCode]
    },

    disabled() {
      const { integrationType } = this
      const { licInfo } = this
      if (!licInfo) return null
      const available = licInfo
        .commonParameters[integrationType.licenseFeatureName]?.isAvailable
      return available == null || !available
    },

    cardStyle() {
      const { integrationType } = this
      return {
        backgroundImage: `url('${integrationType.assets.logo48}')`,
      }
    },

    dialogRoute() {
      const { projectId, integrationCode } = this
      return {
        name: 'Integration',
        params: { projectId },
        query: { dialog: 'integration', type: integrationCode },
      }
    },
  },

  created() {
    this.$store.dispatch('ext/getEnvironment')
  },

  methods: {
    onClick(event) {
      if (this.disabled) event.preventDefault()
    },
  },
}
</script>

<style lang="sass" scoped>
.IntegrationCard
  background-color: white
  background-position: center center
  width: 269px
  width: clamp(150px, 269px, 100vw)
  height: 128px
  box-shadow: 0 1px 2px rgba(0, 0, 0, .2)
  border-radius: 6px
  transition: all 200ms ease-in
  display: block

  &:hover, &:active, &:focus
    box-shadow: 0 8px 16px rgba(0, 0, 0, .12)

  &--disabled
    box-shadow: none !important
    filter: grayscale(50%) opacity(50%)
</style>
