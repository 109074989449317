<template>
  <div
    class="Integration"
    :style="{ minHeight: `calc(100vh - ${appBarHeight}px)` }"
  >
    <AppBarLayout>
      <template #header>
        {{ $t('integration.Integration') }}
      </template>
    </AppBarLayout>

    <div
      v-if="dataLoaded"
      class="Integration__wrapper"
    >
      <div class="Integration__new-cards">
        <IntegrationCard
          v-for="integrationCode in INTEGRATION_ORDER"
          :key="integrationCode"
          :project-id="projectId"
          :integration-code="integrationCode"
          class="Integration__new-card"
        />
      </div>

      <IntegrationList
        v-if="projectIntegrations && projectIntegrations.length"
        :project-id="projectId"
        :integrations="projectIntegrations"
        class="Integration__list"
      />

      <IntegrationDialog
        :value="integrationDialog"
        v-bind="integrationDialogProps"
        @input="!$event && closeIntegrationDialog()"
      />
    </div>
  </div>
</template>

<script>
import { INTEGRATION, INTEGRATION_ORDER } from '../constants'
import { replaceRoute } from '../helpers'

import IntegrationCard from '../components/IntegrationCard'
import IntegrationDialog from '../components/IntegrationDialog'
import IntegrationList from '../components/IntegrationList'
import AppBarLayout from '../layouts/AppBarLayout'

export default {
  name: 'Integration',

  components: {
    AppBarLayout,
    IntegrationCard,
    IntegrationDialog,
    IntegrationList,
  },

  metaInfo() {
    return {
      title: this.$store.getters.title(this.$t('integration.Integration')),
    }
  },

  props: {
    projectId: { type: String, required: true },

    integrationDialog: { type: Boolean, default: false },
    integrationId: { type: String, default: null },
    integrationCode: {
      type: String,
      default: null,
      validator: v => Object.keys(INTEGRATION).includes(v),
    },
  },

  data() {
    return {
      INTEGRATION_ORDER,

      // cached props to avoid flickering dialog
      integrationDialogProps: {},

      dataLoaded: false,
    }
  },

  computed: {
    appBarHeight() { return this.$vuetify.breakpoint.smAndDown ? 56 : 64 },

    projectIntegrations() {
      const { $store, projectId } = this
      return $store.getters['integration/forProject'](projectId)
    },

    title() {
      const { projectIntegrations } = this
      return projectIntegrations &&
        (projectIntegrations.length ? this.$t('integration.Integrations') : this.$t('integration.AddIntegration'))
    },

    baseRoute() {
      const { projectId } = this
      return {
        name: 'Integration',
        params: { projectId },
      }
    },

    integrationDialogParams() { // to watch on
      const { integrationDialog, integrationId } = this
      return { integrationDialog, integrationId }
    },
  },

  watch: {
    projectId: {
      immediate: true,
      handler: 'fetchIntegrations',
    },

    integrationDialogParams: {
      deep: true,
      immediate: true,
      handler: 'toggleIntegrationDialogProps',
    },
  },

  async created() {
    await this.$store.dispatch('license/getInformation')
    await this.$store.dispatch('license/getInformationExpired')
    await this.$store.dispatch('integration/getIntegrationServers')
    this.dataLoaded = true

    // need for jira integration modal. In future this maybe use in 5 step.
    // await this.$store.dispatch('botUser/getList')
  },

  methods: {
    fetchIntegrations() {
      const { $store, projectId } = this
      return $store.dispatch('integration/getForProject', { projectId })
    },

    closeIntegrationDialog() {
      replaceRoute(this.$router, this.baseRoute)
    },

    toggleIntegrationDialogProps() {
      const { projectId, integrationDialog, integrationId, integrationCode } = this
      if (integrationDialog) {
        this.integrationDialogProps = { projectId, integrationId, integrationCode }
      } else {
        this.closeIntegrationDialog()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.Integration
  background-color: #F1F1F6

  &__wrapper
    max-width: 1152px + 48px * 2
    margin: 0 auto
    padding: 105px 48px

  &__new-cards
    margin: 24px -12px 32px
    display: flex
    flex-wrap: wrap

  &__new-card
    margin: 0 12px
</style>
