<template>
  <div class="IntegrationServerForm">
    <h3 class="IntegrationServerForm__heading display-2">
      <span
        class="IntegrationServerForm__title"
        v-text="title"
      />
    </h3>

    <v-window
      class="IntegrationServerForm__window"
    >
      <v-window-item
        class="IntegrationServerForm__window-item"
      >
        <div
          v-if="warnings"
          class="IntegrationServerForm__errors"
        >
          <v-icon
            color="#FFC480"
            v-text="'mdi-alert-outline'"
          />
          <span>{{ warnings }}</span>
        </div>
        <v-form
          ref="integrationServerForm"
          class="IntegrationServerForm__form"
          @submit.native.prevent="create"
          @keyup.native.enter="serverId ? save() : create()"
        >
          <AppTextField
            v-model="integrationServerForm.hostname"
            :label="$t('integration.WebUrl')"
            filled
            placeholder="https://my-jira.atlassian.net"
            name="server-url"
            :error-messages="getErrors('integrationServerForm', 'hostname')"
            @change="saveHostToNameField"
          />
          <AppTextField
            v-model="integrationServerForm.name"
            :label="$t('integration.ServerName')"
            filled
            :placeholder="$t('integration.MyIntegrationServerName')"
            name="server-name"
            :error-messages="getErrors('integrationServerForm', 'name')"
          />
        </v-form>
      </v-window-item>
    </v-window>

    <!-- Form actions -->
    <div class="IntegrationServerForm__actions">
      <v-btn
        :disabled="saving"
        color="primary"
        class="ml-auto"
        @click="serverId ? save() : create()"
      >
        {{ serverId ? $t('integration.Save') : $t('integration.Create') }}
      </v-btn>

      <v-btn
        :disabled="saving"
        outlined
        color="primary"
        class="ml-2"
        @click="$emit('close')"
      >
        {{ $t('integration.Cancel') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import {
  required,
  helpers as vuelidateHelpers,
} from 'vuelidate/lib/validators'
import i18n from '../i18n'

const emptyintegrationServerForm = () => ({
  hostname: '',
  name: '',
})
const initintegrationServerForm = R.pick(['hostname'])

const validateServerUrl = v => {
  if (!vuelidateHelpers.req(v)) return true

  let url
  try {
    url = new URL(v)
  } catch (_) {
    return false
  }
  return !url.search && !url.hash &&
    ['https:', 'http:'].includes(url.protocol) &&
    url.hostname.includes('.')
}

const validationErrors = {
  'integrationServerForm.hostname-required': i18n.t('integration.validationErrors.HostnameRequiredM'),
  'integrationServerForm.hostname-valid': i18n.t('integration.validationErrors.HostnameValidM'),

  'integrationServerForm.name-required': i18n.t('integration.validationErrors.ServerNameRequiredM'),
}

export default {
  name: 'IntegrationServerForm',

  props: {
    // if provided - displays edit form, otherwise shows create form
    serverId: { type: String, default: null },

    integrationServers: { type: Array, default: () => [] },

    saving: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false },
  },

  data() {
    return {
      integrationServerForm: emptyintegrationServerForm(),
      warnings: null,
      // validateintegrationServerForm: false,
    }
  },

  validations() {
    const integrationServerForm = {
      hostname: { required, valid: validateServerUrl },
      name: { required },
    }
    return { integrationServerForm }
  },

  computed: {
    title() {
      const { serverId } = this
      return serverId ? this.$t('integration.EditServerM') : this.$t('integration.CreateNewServerM')
    },

    server() {
      return this.$store.getters['integration/getIntegrationServer'](this.serverId)
    },
  },

  watch: {
    serverId: {
      async handler() {
        const { server } = this

        if (server) {
          this.integrationServerForm.hostname = server.hostname
          this.integrationServerForm.name = server.name

          await this.fetchIntegrationInfo()
        } else {
          this.resetForm()
          this.$nextTick(this.resetValidation)
        }
      },
      immediate: true,
    },

    'integrationServerForm.hostname'(newV, oldV) {
      const { serverId, server } = this
      const integrations = server?.details?.integrations || null

      if (!serverId) return
      if (newV !== server.hostname && oldV !== '') {
        this.warnings = integrations?.length
          ? `${this.$tc('integration.ServerConnectionsMN', integrations?.length)}.
          ${this.$t('integration.ServerConnectionsChangeM')}`
          : null
      }
      if (newV === server.hostname) this.warnings = null
    },
  },

  methods: {
    fetchIntegrationInfo() {
      const { $store, serverId } = this
      return $store.dispatch('integration/getIntegrationServerDetails', { serverId }).then(details => {
        $store.commit('integration/setIntegrationServerDetails', { serverId, details })
      })
    },

    saveHostToNameField() {
      const { integrationServerForm } = this

      if (!integrationServerForm.name) integrationServerForm.name = integrationServerForm.hostname
    },

    async save() {
      const { serverId, integrationServerForm: { hostname, name }, $store } = this

      // Vuelidate
      this.$v.integrationServerForm.$touch()
      if (this.$v.integrationServerForm.$anyError) return

      this.$emit('update:saving', true)
      await $store.dispatch('integration/patchIntegrationServer', { serverId, hostname, name })
        .then(() => {
          this.$emit('update:saving', false)
          this.$emit('close')
          this.resetForm()
          this.$nextTick(this.resetValidation)
        })
        .catch(() => this.$emit('update:saving', false))
    },

    async create() {
      const { integrationServerForm: { hostname, name }, $store } = this

      // Vuelidate
      this.$v.integrationServerForm.$touch()
      if (this.$v.integrationServerForm.$anyError) return

      this.$emit('update:saving', true)
      await $store.dispatch('integration/postIntegrationServer', { hostname, name })
        .then(() => {
          this.$emit('update:saving', false)
          this.$emit('close')
          this.resetForm()
          this.$nextTick(this.resetValidation)
        })
        .catch(() => this.$emit('update:saving', false))
    },

    resetForm() {
      const { integration } = this
      if (integration) {
        this.integrationServerForm = initintegrationServerForm(integration)
      } else {
        this.integrationServerForm = emptyintegrationServerForm()
      }
    },

    resetValidation() { // public method
      this.$v.$reset()
    },

    getErrors(form, field) {
      const v = this.$v[form][field]

      if (!v.$dirty) return []
      return Object.entries(v)
        .filter(([k, _]) => !k.startsWith('$'))
        .filter(([_, v]) => !v)
        .map(([k, _]) =>
          validationErrors[`${form}.${field}-${k}`] ||
          `${this.$t('integration.validationErrors.ValidationError')} ${field} ${k}`)
    },
  },
}
</script>

<style lang="sass" scoped>
.IntegrationServerForm
  display: flex
  flex-direction: column
  min-height: 360px

  &__heading
    margin: 43px 48px 49px
    display: flex
    align-items: center

  &__title
    position: relative
    top: 3px

  &__window
    display: flex
    flex: 1

    ::v-deep .v-window__container
      width: 100%

  &__window-item
    height: 100%
    padding: 0 48px

  &__form
    height: 100%

  &__actions
    display: flex
    padding: 32px 48px

  &__errors
    display: flex
    align-items: center
    margin-bottom: 20px

    & span
      margin-left: 10px
</style>
