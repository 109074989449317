<template>
  <div class="SetupJiraForm">
    <h3 class="SetupJiraForm__heading display-2">
      <img
        class="SetupJiraForm__jira-logo"
        :alt="$t('layout.AltJiraLogo')"
        :src="require('../assets/images/jira-logo-32.png')"
      >
      <h1
        class="SetupJiraForm__title"
        v-text="title"
      />
    </h3>

    <v-window
      v-model="windowStep"
      class="SetupJiraForm__window"
    >
      <!-- STEP 1: Hostname form -->
      <v-window-item
        :value="STEPS.HOSTNAME_CONNECTION"
        class="SetupJiraForm__window-item"
      >
        <Steppers
          :number-of-step="totalSteps"
          :active-step="currentStepNumber"
          class="SetupJiraForm__steppers"
        />
        <v-form
          ref="hostnameForm"
          class="SetupJiraForm__form"
          @submit.native.prevent="saveFirstStep"
        >
          <AppSelect
            v-model="hostnameForm.hostname"
            :label="$t('integration.WebUrl')"
            :items="integrationServers"
            :return-object="true"
            :item-text="hostnameWithName"
            item-value="id"
            filled
            :no-data-text="$t('integration.AskToCreateServer')"
            :hint="integrationServers.length ? '' : $t('integration.AskToCreateServer')"
            :persistent-hint="integrationServers.length ? false : true"
            placeholder="https://my-jira.atlassian.net"
            name="jira-url"
            :error-messages="getErrors('hostnameForm', 'hostname')"
            @change="goToConnectionStep"
          >
            <template #selection="{ item }">
              {{ item.hostname }}
            </template>
            <template #item="{ item }">
              <div class="SetupJiraForm__hostname-item">
                <span>{{ item.name }}</span>
                <span class="SetupJiraForm__hostname-item-host">{{ item.hostname }}</span>
              </div>
            </template>
          </AppSelect>

          <div v-if="hostnameForm.hostname.hostname && isFetchedAuthType">
            <AppTextField
              v-show="authType !== JIRA_AUTH_TYPE.TOKEN"
              v-model="hostnameForm.username"
              :label="authType === JIRA_AUTH_TYPE.CLOUD ? $t('integration.Email') : $t('integration.Login')"
              :type="authType === JIRA_AUTH_TYPE.CLOUD ? 'email' : 'text'"
              filled
              :placeholder="(currentUser && currentUser.userEmail) || 'user@example.com'"
              name="email"
              autocomplete="email"
              :error-messages="getErrors('hostnameForm', 'username')"
            />

            <AppTextField
              v-model="hostnameForm.token"
              :label="authType === JIRA_AUTH_TYPE.BASIC ? $t('integration.Password') : $t('integration.Token')"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :error-messages="getErrors('hostnameForm', 'token')"
              :type="showPassword ? 'text' : 'password'"
              filled
              margins-with-hidden-details="mb-2"
              name="jira-token"
              autocomplete="new-password"
              @click:append="showPassword = !showPassword"
            />
            <div class="SetupJiraForm__help-text">
              <template v-if="authType === JIRA_AUTH_TYPE.BASIC">
                {{ $t('integration.PasswordJiraM') }}
              </template>
              <template v-else>
                {{ $t('integration.ApiTokenM') }}
              </template>
            </div>

            <AppTextField
              v-model="hostnameForm.name"
              :label="$t('integration.ConnectName')"
              filled
              name="connection-name"
              :placeholder="$t('integration.MyJiraConnection')"
              :error-messages="getErrors('hostnameForm', 'name')"
            />
          </div>
        </v-form>
      </v-window-item>

      <!-- STEP 2: Project/Issue types -->
      <v-window-item
        :value="STEPS.TYPES"
        class="SetupJiraForm__window-item"
      >
        <Steppers
          :number-of-step="totalSteps"
          :active-step="currentStepNumber"
          class="SetupJiraForm__steppers"
        />
        <v-form
          ref="typesForm"
          class="SetupJiraForm__form"
        >
          <AppSelect
            v-model="projectModel"
            filled
            :items="jiraProjects || []"
            item-text="name"
            item-value="key"
            :label="$t('integration.Project')"
            :no-data-text="$t('integration.NoProjectsMQ')"
            :menu-props="{ offsetY: true }"
            :error-messages="getErrors('typesForm', 'jiraProjectKey')"
            margins-with-hidden-details="mb-0"
          >
            <template #item="{ item: project }">
              [{{ project.key }}] {{ project.name }}
            </template>
          </AppSelect>

          <AppSelect
            v-model="issueTypeModel"
            filled
            :items="selectedProjectIssueTypes || []"
            item-text="name"
            item-value="id"
            :label="$t('integration.IssueType')"
            return-object
            :no-data-text="projectModel
              ? $t('integration.NoIssueTypesMQ')
              : $t('integration.SelectProjectM')"
            :menu-props="{ offsetY: true }"
            :error-messages="getErrors('typesForm', 'jiraIssueType')"
            margins-with-hidden-details="mb-0"
          >
            <template #selection="{ item: issueType }">
              <img
                :alt="$t('layout.altIssueType')"
                :src="issueType.iconUrl"
              >
              <span
                class="mx-2"
                v-text="issueType.name"
              />
            </template>

            <template #item="{ item: issueType }">
              <img
                :alt="$t('layout.altIssueType')"
                :src="issueType.iconUrl"
              >
              <span
                class="mx-4"
                v-text="issueType.name"
              />
              <span
                class="text--secondary"
                v-text="issueType.description"
              />
            </template>
          </AppSelect>

          <!--          <v-checkbox-->
          <!--            v-model="typesForm.sendTestIssue"-->
          <!--            label="Send test issue"-->
          <!--            class="mt-2"-->
          <!--            hide-details-->
          <!--          />-->
          <v-checkbox
            v-model="typesForm.backwardSyncEnabled"
            :label="$t('integration.ReverseSync')"
            hide-details
          />
          <v-checkbox
            v-model="typesForm.syncCommentsEnabled"
            label="Sync comments"
            hide-details
          />
        </v-form>
      </v-window-item>

      <!-- STEP 3: Mapping statuses -->
      <v-window-item
        :value="STEPS.STATUS_MAPPING"
        class="SetupJiraForm__window-item"
      >
        <Steppers
          :number-of-step="totalSteps"
          :active-step="currentStepNumber"
          class="SetupJiraForm__steppers"
        />
        <div class="SetupJiraForm__status-header">
          <div class="SetupJiraForm__jira-status-external">
            <h2>{{ $t('integration.Jira') }}</h2>
          </div>
          <h2>{{ $t('integration.Apiary') }}</h2>
        </div>

        <JiraStatusMappingForm
          :backward-status-mapping-form="backwardStatusMappingForm"
          :external-statuses="externalStatuses"
          :local-statuses="localStatuses"
        />
      </v-window-item>

      <!-- STEP 4: Required jira fields -->
      <v-window-item
        :value="STEPS.REQUIRED_FIELDS"
        class="SetupJiraForm__window-item"
      >
        <Steppers
          :number-of-step="totalSteps"
          :active-step="currentStepNumber"
          class="SetupJiraForm__steppers"
        />
        <v-form
          v-if="requiredFields && !haveUnsupportedField"
          ref="requiredFields"
          class="SetupJiraForm__form-status"
        >
          <div
            v-if="!haveUnsupportedField && requiredFields.length"
            class="SetupJiraForm__status-header"
          >
            <h2>{{ $t('integration.DefaultValuesRequiredFieldsM') }}</h2>
          </div>
          <JiraRequiredFields
            v-for="field in requiredFields"
            :key="field.name"
            :field="field"
            :integration-id="integrationId || savedIntegrationId"
            :saved-field-value="savedRequiredField(field)"
          />
          <div v-if="showOptionalFields">
            <div
              v-if="!haveUnsupportedField"
              class="SetupJiraForm__status-header"
            >
              <h2>{{ $t('integration.DefaultValuesOptionalFieldsM') }}</h2>
            </div>
            <JiraRequiredFields
              v-for="field in nonRequiredFields"
              :key="field.name"
              :field="field"
              :integration-id="integrationId || savedIntegrationId"
              :show-unsupported-fields="true"
              :saved-field-value="savedRequiredField(field)"
            />
          </div>
          <v-btn
            text
            color="primary"
            @click="showOptionalFields = !showOptionalFields"
          >
            {{ showOptionalFields ? $t('integration.HideOptional') : $t('integration.ShowOptional') }}
          </v-btn>
        </v-form>
        <div v-else>
          <div v-if="requiredFields">
            <h2 class="mb-4">
              {{ $t('integration.UnsupportedFieldsM') }}
            </h2>
            <span>{{ $t('integration.UnsupportedFieldsInIssueTypeM') }}</span><br>
            <span>{{ $t('integration.SendDetailsTo') }}
              <a :href="`mailto:${$t('layout.emailSupport')}?subject=${$t('integration.JiraUnsupportedFields')}&body=${unsupportedFieldName}`">{{ $t('layout.emailSupport') }}</a>
            </span>

            <PreCopy
              v-for="field in unsupportedField"
              :key="field.name"
              :title="field.displayName || $t('integration.UnsupportedField')"
              :text="getDetailsInString(field.systemDetails)"
            />
          </div>
          <div v-else>
            <v-icon
              color="#FFC480"
              v-text="'mdi-alert-outline'"
            />
            <span>
              {{ $t('integration.NoRequiredFields') }}
            </span>
          </div>
        </div>
      </v-window-item>
    </v-window>

    <!-- Form actions -->
    <div
      v-if="haveUnsupportedField && windowStep === STEPS.REQUIRED_FIELDS"
      class="SetupJiraForm__actions"
    >
      <v-btn
        :disabled="saving"
        outlined
        color="error"
        class="mr-auto"
        @click="deleteIntegration"
      >
        {{ $t('integration.Delete') }}
      </v-btn>

      <v-btn
        depressed
        color="primary"
        class="ml-auto"
        @click="copyToClipboard"
      >
        <v-icon
          small
          class="mr-2"
        >
          mdi-content-copy
        </v-icon>
        {{ $t('integration.Copy') }}
      </v-btn>
      <v-btn
        outlined
        color="primary"
        class="ml-2"
        @click="resetForm"
      >
        {{ $t('integration.CreateOther') }}
      </v-btn>
    </div>
    <div
      v-else
      class="SetupJiraForm__actions"
    >
      <v-btn
        v-if="windowStep !== STEPS.HOSTNAME_CONNECTION"
        :disabled="saving"
        outlined
        color="primary"
        class="mr-auto"
        :min-width="40"
        :width="40"
        @click="previousStep"
      >
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn
        v-if="savedIntegrationId != null && windowStep === STEPS.HOSTNAME_CONNECTION"
        :disabled="saving"
        outlined
        color="error"
        class="mr-auto"
        @click="deleteIntegration"
      >
        {{ $t('integration.Delete') }}
      </v-btn>
      <div
        v-if="statusMappingError"
        class="SetupJiraForm__errors mr-auto"
      >
        <v-icon
          color="#FFC480"
          v-text="'mdi-alert-outline'"
        />
        <span>{{ statusMappingError }}</span>
      </div>
      <v-btn
        v-if="isShowNextButton"
        :disabled="saving"
        :loading="saving"
        depressed
        color="primary"
        class="ml-auto"
        @click="nextStep"
      >
        {{ $t('integration.Next') }}
      </v-btn>
      <v-btn
        v-if="isShowDoneButton"
        :disabled="saving"
        :loading="saving"
        depressed
        color="primary"
        class="ml-auto"
        @click="finalSave"
      >
        {{ $t('integration.Save') }}
      </v-btn>
      <v-btn
        :disabled="saving"
        outlined
        color="primary"
        class="ml-2"
        @click="$emit('close')"
      >
        {{ $t('integration.Cancel') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Steppers from '@/components/Steppers'
import JiraRequiredFields from '@/components/JiraRequiredFields'
import PreCopy from '@/components/PreCopy'
import JiraStatusMappingForm from '@/components/JiraStatusMappingForm'

import * as R from 'ramda'
import {
  required,
  requiredIf,
  email,
  helpers as vuelidateHelpers,
} from 'vuelidate/lib/validators'

import { JIRA, JIRA_AUTH_TYPE } from '../constants'
import copyToClipboard from 'copy-to-clipboard'
import i18n from '../i18n'

const STEPS = Object.freeze({
  HOSTNAME_CONNECTION: 'JIRA__STEPS__HOSTNAME__CONNECTION',
  TYPES: 'JIRA__STEPS__TYPES',
  STATUS_MAPPING: 'JIRA__STEPS__STATUS_MAPPING',
  REQUIRED_FIELDS: 'JIRA__REQUIRED__FIELDS',
})

const STEP_NUMBER = {
  [STEPS.HOSTNAME_CONNECTION]: 1,
  [STEPS.TYPES]: 2,
  [STEPS.STATUS_MAPPING]: 3,
  [STEPS.REQUIRED_FIELDS]: 4,
}

const JIRA_WARNINGS = {
  NOT_HTTPS: i18n.t('integration.JiraWarningNotHttpsM'),
  EMPTY_BACKWARD_STATUS: i18n.t('integration.JiraWarningEmptyBackwardStatusM'),
}

const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value)
}

const emptyHostnameForm = () => ({
  hostname: {
    hostname: '',
  },
  name: '',
  username: '',
  token: '',
})

// TODO: sendTestIssue not working now. Return checkbox after adding functionality on engine
const emptyTypesForm = () => ({
  jiraProjectKey: null,
  jiraIssueType: null,
  sendTestIssue: false,
  backwardSyncEnabled: true,
  syncCommentsEnabled: true,
})
const initTypesForm = R.pipe(
  R.pick(['jiraProjectKey', 'jiraIssueType', 'syncCommentsEnabled']),
  R.assoc('sendTestIssue', false),
  R.assoc('backwardSyncEnabled', true),
  R.over(R.lensProp('syncCommentsEnabled'), R.defaultTo(true)),
)

const emptyBackwardStatusMappingForm = () => ([{
  externalStatusID: null,
  localStatus: null,
  id: null,
  name: null,
}])

const validateJiraUrl = v => {
  if (!vuelidateHelpers.req(v)) return true

  let url
  try {
    url = new URL(v)
  } catch (_) {
    return false
  }
  return !url.search && !url.hash &&
    ['https:', 'http:'].includes(url.protocol) &&
    url.hostname.includes('.')
}

const validationErrors = {
  'hostnameForm.hostname.hostname-required': i18n.t('integration.validationErrors.HostnameRequiredM'),
  'hostnameForm.hostname.hostname-valid': i18n.t('integration.validationErrors.HostnameValidM'),

  'hostnameForm.name-required': i18n.t('integration.validationErrors.NameRequiredM'),

  'hostnameForm.username-required': i18n.t('integration.validationErrors.UsernameRequiredM'),
  'hostnameForm.username-valid': i18n.t('integration.validationErrors.UsernameValidM'),

  'hostnameForm.token-required': i18n.t('integration.validationErrors.TokenRequiredM'),

  'typesForm.jiraProjectKey-required': i18n.t('integration.validationErrors.JiraProjectKeyRequiredM'),
  'typesForm.jiraIssueType-required': i18n.t('integration.validationErrors.JiraIssueTypeRequiredM'),
}

export default {
  name: 'SetupJiraForm',

  components: {
    Steppers,
    JiraRequiredFields,
    PreCopy,
    JiraStatusMappingForm,
  },

  props: {
    // required as long as our integrations are project scoped
    projectId: { type: String, default: null },
    // if provided - displays edit form, otherwise shows create form
    integrationId: { type: String, default: null },

    saving: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false },
  },

  data() {
    return {
      JIRA_AUTH_TYPE,
      STEPS,

      savedIntegrationId: this.integrationId,

      windowStep: STEPS.HOSTNAME,

      // Initial value does not really matter: it will be set when going to step 2,
      // right after the hostname is sent to the server.
      authType: JIRA_AUTH_TYPE.CLOUD,
      isFetchedAuthType: false,

      hostnameForm: emptyHostnameForm(),
      // validateHostnameForm: false,

      typesForm: emptyTypesForm(),
      // validateTypesForm: false,

      backwardStatusMappingForm: emptyBackwardStatusMappingForm(),

      statusMappingError: null,

      fieldsDefaultValues: [],

      showOptionalFields: false,

      showPassword: false,
    }
  },

  validations() {
    const hostnameForm = {
      hostname: {
        hostname: { required, valid: validateJiraUrl },
      },
      name: { required },
      username: { },
      token: {
        // required when creating or when integration is a draft yet
        required: requiredIf(function() {
          return !this.integration || this.integration.isDraft
        }),
      },
    }
    if (this.authType === JIRA_AUTH_TYPE.CLOUD) {
      hostnameForm.username.valid = email
    }
    if (this.authType !== JIRA_AUTH_TYPE.TOKEN) {
      hostnameForm.username.required = required
    }
    const typesForm = {
      jiraProjectKey: { required },
      jiraIssueType: { required },
    }

    return {
      hostnameForm,
      typesForm,
    }
  },

  computed: {
    // canStayOnOtherStep() {
    //   const { integration } = this
    //   return integration && integration.state === INTEGRATION_STATE.AUTHENTICATED
    // },

    integrationRoute() {
      const { projectId } = this
      return {
        name: 'Integration',
        params: { projectId },
        query: { dialog: 'integration', type: 'JIRA' },
      }
    },

    totalSteps() {
      const { typesForm, meta } = this

      if (!typesForm.backwardSyncEnabled &&
        (Array.isArray(meta?.requiredFields) && !meta?.requiredFields?.length && !meta?.nonRequiredFields?.length)
      ) { return Object.values(STEPS).length - 2 }
      if (!typesForm.backwardSyncEnabled ||
        ((Array.isArray(meta?.requiredFields) && !meta?.requiredFields?.length && !meta?.nonRequiredFields?.length))
      ) { return Object.values(STEPS).length - 1 }

      return Object.values(STEPS).length
    },

    currentStepNumber() { return STEP_NUMBER[this.windowStep] },

    protocol() { return window.location.protocol || 'https:' },

    isShowNextButton() {
      const { currentStepNumber, totalSteps } = this

      return totalSteps > currentStepNumber
    },

    isShowDoneButton() {
      const { currentStepNumber, totalSteps } = this

      return currentStepNumber >= totalSteps
    },

    integration() {
      const { $store, savedIntegrationId: integrationId } = this
      return integrationId && $store.getters['integration/get'](integrationId)
    },

    meta() { return this.integration?.meta || null },

    requiredFields() {
      const { meta } = this
      return meta?.requiredFields || null
    },

    nonRequiredFields() {
      const { meta } = this
      return meta?.nonRequiredFields || null
    },

    integrationServers() {
      return Object.values(this.$store.getters['integration/integrationServers']) || []
    },

    title() {
      if (STEP_NUMBER[this.windowStep] === 3) return this.$t('integration.StatusMapping')
      return this.$t('integration.Configure')
    },

    currentUser() { return this.$store.getters['user/current'] },

    jiraProjects() {
      const { meta } = this
      return meta && meta.projects
    },

    externalStatuses() {
      const { meta } = this
      return meta && meta.externalStatuses
    },

    localStatuses() {
      const { meta } = this
      return meta && meta.localStatuses
    },

    projectModel: {
      get() {
        const { jiraProjects, typesForm: { jiraProjectKey } } = this
        const fallback = () => ({
          key: jiraProjectKey,
          name: jiraProjectKey,
          issuetypes: [],
        })

        if (!jiraProjectKey) return null
        if (!jiraProjects) return fallback()

        const jiraProject = jiraProjects &&
          jiraProjects.find(({ key }) => key === jiraProjectKey)
        return jiraProject
          ? R.pick(['key', 'name', 'issuetypes'], jiraProject)
          : fallback()
      },

      set(projectKey) { this.typesForm.jiraProjectKey = projectKey },
    },

    selectedProjectIssueTypes() {
      const { projectModel } = this
      return projectModel?.issuetypes ?? null
    },

    issueTypeModel: {
      get() {
        const { selectedProjectIssueTypes, typesForm: { jiraIssueType } } = this

        const fallback = () => ({
          name: jiraIssueType?.name || jiraIssueType,
          description: '',
          iconUrl: null,
          id: null,
        })

        if (!jiraIssueType) return null
        if (!selectedProjectIssueTypes) return fallback()

        const selectedType = selectedProjectIssueTypes &&
          selectedProjectIssueTypes
            .find(({ name, id }) => {
              if (jiraIssueType?.id && id === jiraIssueType.id) return true
              if (!jiraIssueType?.id && name === (jiraIssueType?.name || jiraIssueType)) return true
            })

        return selectedType
          ? R.pick(['name', 'description', 'iconUrl', 'id'], selectedType)
          : fallback()
      },

      set(issueTypeName) { this.typesForm.jiraIssueType = issueTypeName },
    },

    haveUnsupportedField() {
      return this.meta?.requiredFields?.some(field => field.type === 'UNSUPPORTED') || false
    },

    unsupportedField() {
      const unsupportedRequiredFields = this.meta?.requiredFields?.filter(field => field.type === 'UNSUPPORTED') || []
      const unsupportedNonRequiredFields = this.meta?.nonRequiredFields?.filter(field => field.type === 'UNSUPPORTED') || []

      return [...unsupportedRequiredFields, ...unsupportedNonRequiredFields]
    },

    unsupportedFieldName() {
      return this.unsupportedField.map(field => field.displayName).join(', ')
    },
  },

  watch: {
    currentStepNumber: {
      immediate: true,
      handler(number) {
        const { typesForm: { backwardSyncEnabled }, hostnameForm: { hostname: { hostname } } } = this
        if (backwardSyncEnabled && number === 2 && hostname?.includes('http:')) {
          this.statusMappingError = JIRA_WARNINGS.NOT_HTTPS
        } else {
          this.statusMappingError = ''
        }
      },
    },

    'typesForm.backwardSyncEnabled': {
      immediate: true,
      handler(backwardSyncEnabled) {
        const { hostnameForm: { hostname: { hostname } }, currentStepNumber } = this
        if (backwardSyncEnabled && currentStepNumber === 2 && hostname?.includes('http:')) {
          this.statusMappingError = JIRA_WARNINGS.NOT_HTTPS
        } else {
          this.statusMappingError = ''
        }
      },
    },

    backwardStatusMappingForm: {
      immediate: true,
      deep: true,
      handler() {
        this.statusMappingError = ''
      },
    },

    isOpen: {
      immediate: true,
      handler(v) {
        if (!v) {
          this.resetForm()
          this.$emit('update:saving', false)
          this.$emit('close')
        } else {
          if (this.integrationId) {
            this.$nextTick(() => {
              this.restoreServer(this.integrationId)
            })
          }
        }
      },
    },

    integration: {
      immediate: true,
      handler() {
        // Unable to connect to the remote JIRA server
        // this.initOrResetForm()
        this.$nextTick(this.resetValidation)
      },
    },

    // canStayOnOtherStep: {
    //   immediate: true,
    //   handler(canStay) {
    //     if (!canStay) this.windowStep = STEPS.HOSTNAME_CONNECTION
    //   },
    // },

    jiraProjects: {
      immediate: true,
      handler(jiraProjects) {
        if (jiraProjects?.length && !this.typesForm.jiraProjectKey) {
          this.typesForm.jiraProjectKey = jiraProjects[0].key
        }
      },
    },

    // projectModel() {
    //   const issueType = this.typesForm.jiraIssueType
    //   const issueTypes = this.selectedProjectIssueTypes || []
    //   if (issueType && !issueTypes.some(it => it.name === issueType)) {
    //     this.typesForm.jiraIssueType = issueTypes?.length
    //       ? issueTypes[0].name
    //       : null
    //   }
    // },

    selectedProjectIssueTypes: {
      immediate: true,
      handler(issueTypes) {
        if (issueTypes?.length && !this.typesForm.jiraIssueType) {
          this.typesForm.jiraIssueType = issueTypes[0].name
        }
      },
    },

    externalStatuses: {
      immediate: true,
      handler(externalStatuses) {
        const { integrationId, localStatuses, savedIntegrationId } = this
        const integration = this.$store.getters['integration/get'](integrationId || savedIntegrationId)

        if (externalStatuses && localStatuses && externalStatuses.length) {
          this.backwardStatusMappingForm = R.clone(externalStatuses)
          this.backwardStatusMappingForm.map(status => {
            this.$set(status, 'externalStatusID', status.id)
            this.$set(status, 'localStatus', null)
          })
        }

        if ((integrationId || savedIntegrationId) && integration?.backwardStatusMapping?.length) {
          this.backwardStatusMappingForm.map(statusForm => {
            integration.backwardStatusMapping.map(statusFromServer => {
              if (statusForm.externalStatusID === statusFromServer.externalStatusID) statusForm.localStatus = statusFromServer.localStatus
            })
          })
        }
      },
    },

    requiredFields(v) {
      if (!v?.length) this.showOptionalFields = true
    },
  },

  methods: {
    copyToClipboard() {
      const value = JSON.stringify(this.unsupportedField, null, '\t')

      copyToClipboard(value)
      this.$store.commit('$snackbar/setMessage', {
        message: this.$t('integration.Copied'),
        timeout: 2000,
      })
    },

    getDetailsInString(field) {
      return JSON.stringify(field, null, '\t')
    },

    restoreServer(integrationId) {
      this.savedIntegrationId = integrationId

      const integration = this.$store.getters['integration/get'](integrationId)
      const server = this.$store.getters['integration/getIntegrationServer'](integration.serverID)

      if (server) {
        this.hostnameForm.hostname = server
        this.goToConnectionStep()
        this.hostnameForm.name = integration.name
        this.hostnameForm.username = integration.username
        this.hostnameForm.token = integration.token
        this.typesForm.backwardSyncEnabled = integration.backwardSyncEnabled
        this.typesForm.syncCommentsEnabled = integration.syncCommentsEnabled
        this.typesForm.jiraIssueType = integration.jiraIssueType
        this.typesForm.jiraProjectKey = integration.jiraProjectKey
      }
    },

    savedRequiredField(field) {
      const { integration } = this

      const defVal = integration?.fieldsDefaultValues?.find(value => value.field === field.name)
      if (defVal) {
        return defVal.value
      }
      return null
    },

    async finalSave() {
      await this.updateConnection()
      this.$emit('close')
    },

    nextStep() {
      const { windowStep } = this

      if (windowStep === STEPS.HOSTNAME_CONNECTION) this.saveFirstStep()
      if (windowStep === STEPS.TYPES) this.saveSecondStep()
      if (windowStep === STEPS.STATUS_MAPPING) this.saveThirdStep()
    },

    previousStep() {
      const { typesForm: { backwardSyncEnabled } } = this

      if (STEP_NUMBER[this.windowStep] === 1) return
      if (!backwardSyncEnabled) this.windowStep = getKeyByValue(STEP_NUMBER, STEP_NUMBER[this.windowStep] - 1)
      this.windowStep = getKeyByValue(STEP_NUMBER, STEP_NUMBER[this.windowStep] - 1)
    },

    hostnameWithName(server) {
      return `${server.hostname} - ${server.name}`
    },

    initConnection(integration) {
      return R.pipe(
        R.pick(['name', 'username', 'hostname', 'token']),
      )(integration)
    },

    initOrResetForm() {
      const { integration } = this
      if (integration) {
        this.hostnameForm = this.initConnection(integration)
        this.typesForm = initTypesForm(integration)
      } else {
        this.hostnameForm = emptyHostnameForm()
        this.typesForm = emptyTypesForm()
      }
    },

    resetForm() {
      this.backwardStatusMappingForm = emptyBackwardStatusMappingForm()
      this.statusMappingError = null
      this.fieldsDefaultValues = []
      this.hostnameForm = emptyHostnameForm()
      this.typesForm = emptyTypesForm()
      this.savedIntegrationId = null
      this.windowStep = STEPS.HOSTNAME_CONNECTION
    },

    resetValidation() { // public method
      this.$v.$reset()
    },

    getErrors(form, field) {
      const v = this.$v[form][field]
      if (!v?.$dirty) return []
      return Object.entries(v)
        .filter(([k, _]) => !k.startsWith('$'))
        .filter(([_, v]) => !v)
        .map(([k, _]) =>
          validationErrors[`${form}.${field}-${k}`] ||
          `${this.$t('integration.validationErrors.ValidationError')} ${field} ${k}`)
    },

    fetchIntegrationJiraInfo() {
      return this.$store.dispatch('integration/getJiraInfo', {
        projectId: this.projectId,
        ...this.hostnameForm.hostname,
      })
    },

    async goToConnectionStep() {
      this.$emit('update:saving', true)
      await this.fetchIntegrationJiraInfo().then(({ authentication: authType }) => {
        this.authType = authType
        this.isFetchedAuthType = true
      }).finally(() => this.$emit('update:saving', false))
    },

    saveFirstStep() {
      // Native validation
      const form = this.$refs.hostnameForm
      if (form.reportValidity && !form.reportValidity()) return

      // Vuelidate
      this.$v.hostnameForm.$touch()

      if (this.$v.hostnameForm.$anyError) return

      const { integration } = this

      const saved = integration
        ? this.updateConnection()
        : this.createIntegration()
      const authenticated = saved
        .then(integrationId => {
          this.savedIntegrationId = integrationId
          return this.fetchMeta(integrationId, true)
            .then(() => true)
            .catch(() => false)
        })

      this.$emit('update:saving', true)
      return authenticated
        .then(authenticated => {
          // couldn't establish the connection
          // snackbar with the reason should be visible
          if (!authenticated) return
          this.windowStep = STEPS.TYPES
        })
    },

    async saveSecondStep() {
      const { windowStep, typesForm: { backwardSyncEnabled } } = this

      if (windowStep === STEPS.TYPES && backwardSyncEnabled) {
        await this.submitTypes()
        await this.fetchMeta(this.integrationId)
        this.windowStep = STEPS.STATUS_MAPPING
      } else if (windowStep === STEPS.TYPES && !backwardSyncEnabled) {
        return this.saveThirdStep()
      }
    },

    async saveThirdStep() {
      if (this.windowStep === STEPS.STATUS_MAPPING) {
        const { backwardStatusMappingForm, typesForm: { backwardSyncEnabled }, externalStatuses } = this

        this.statusMappingError = null
        if (!backwardStatusMappingForm.filter(status => status.localStatus).length &&
          backwardSyncEnabled &&
          externalStatuses?.length
        ) {
          this.statusMappingError = JIRA_WARNINGS.EMPTY_BACKWARD_STATUS
          return
        }

        await this.updateConnection()
        await this.fetchMeta(this.integrationId)
        this.windowStep = STEPS.REQUIRED_FIELDS
        return
      }

      await this.submitTypes()
      await this.fetchMeta(this.integrationId).then(() => {
        if (Array.isArray(this.meta.requiredFields) &&
          (!this.meta.requiredFields || !this.meta.requiredFields?.length) &&
          Array.isArray(this.meta.nonRequiredFields) &&
          (!this.meta.nonRequiredFields || !this.meta.nonRequiredFields?.length)
        ) {
          this.initOrResetForm()
          this.$emit('close')
        }
      })
      this.windowStep = STEPS.REQUIRED_FIELDS
    },

    submitTypes() {
      // Native validation
      const form = this.$refs.typesForm

      if (form.reportValidity && !form.reportValidity()) return

      // Vuelidate
      this.$v.typesForm.$touch()
      if (this.$v.typesForm.$anyError) return

      const { $store, savedIntegrationId: integrationId, typesForm, issueTypeModel, projectId } = this
      const clonedTypesForm = R.clone(typesForm)
      clonedTypesForm.jiraIssueType = issueTypeModel

      this.$emit('update:saving', true)
      return $store.dispatch('integration/updateJira', {
        projectId,
        integrationId,
        integration: {
          id: integrationId,
          ...clonedTypesForm,
        },
      })
    },

    async createIntegration() {
      const { $store, projectId, hostnameForm } = this
      const integrationId = await $store.dispatch('integration/createForProject', {
        projectId,
        integration: {
          ...hostnameForm,
          integrationCode: JIRA,
        },
      })
      // await replaceRoute($router, {
      //   name: 'Integration',
      //   params: { projectId },
      //   query: { dialog: 'integration', integration: integrationId },
      // })
      return integrationId
    },

    async updateConnection() {
      const { $store, savedIntegrationId: integrationId, hostnameForm, windowStep, projectId, fieldsDefaultValues } = this
      const integration = {
        id: integrationId,
        ...hostnameForm,
      }

      integration.hostname = integration.hostname.hostname
      if (windowStep === STEPS.STATUS_MAPPING) {
        const backwardStatusMapping = R.pipe(
          R.map(v => R.omit(['id', 'name'], v)),
          R.filter(v => v.localStatus),
        )(this.backwardStatusMappingForm)

        if (!backwardStatusMapping.length) return

        integration.backwardStatusMapping = backwardStatusMapping
        integration.backwardSyncEnabled = true
      }

      if (windowStep === STEPS.REQUIRED_FIELDS) {
        this.$refs.requiredFields?.inputs.forEach(input => {
          if (Array.isArray(input.lazyValue) && input.lazyValue?.length) {
            this.fieldsDefaultValues.push({
              field: input.id,
              value: input.lazyValue,
            })
          }

          if (!Array.isArray(input.lazyValue) && input.lazyValue) {
            this.fieldsDefaultValues.push({
              field: input.id,
              value: input.lazyValue,
            })
          }
        })

        integration.fieldsDefaultValues = fieldsDefaultValues
      }

      this.$emit('update:saving', true)

      await $store.dispatch('integration/updateJira', {
        projectId,
        integrationId,
        integration,
      })

      return integrationId
    },

    fetchMeta(integrationId = null, initialQuery = false) {
      const { $store } = this
      integrationId = integrationId || this.savedIntegrationId
      return $store.dispatch('integration/getJiraMeta', { integrationId, initialQuery })
        .finally(() => this.$emit('update:saving', false))
    },

    async deleteIntegration() {
      const { $store, projectId, savedIntegrationId: integrationId } = this

      if (!(await $store.dispatch('confirm/openDialog', {
        title: this.$t('integration.DeleteIntegrationQ'),
        subtitle: this.$t('integration.DeleteIntegrationPermanentlyM'),
      }))) return

      this.$emit('update:saving', true)
      $store.dispatch('integration/delete', {
        projectId,
        integration: { id: integrationId, integrationCode: JIRA },
        // we need to close modal first, only then commit changes
        commitChanges: false,
      })
        .finally(() => this.$emit('update:saving', false))
        .then(() => this.$emit('close'))
        .then(() => this.$nextTick())
        .then(() => $store.commit(
          'integration/removeIntegration',
          { projectId, integrationId },
        ))
    },
  },
}
</script>

<style lang="sass" scoped>
.SetupJiraForm
  display: flex
  flex-direction: column
  min-height: 588px
  max-height: 588px

  &__error
    background: #FAF4D6
    border-radius: 4px
    padding: 8px 24px

  &__hostname-item
    display: flex
    flex-flow: column

    &-host
      font-size: 12px
      color: #9E9E9E

  &__steppers
    width: 100%
    padding: 0 0 24px 0

  &__status-header
    display: flex
    flex-wrap: wrap
    align-items: center
    margin-bottom: 20px

    &-subtext
      color: #A09EB9

  &__heading
    margin: 36px 24px
    display: flex
    align-items: center

  &__title
    position: relative
    font-size: 32px
    font-weight: 500

  &__jira
    &-logo
      margin-right: 14.33px
      height: 32px
      width: 89px

    &-status-external
      min-width: 50%
      max-width: 50%
      font-weight: 500
      display: flex
      align-items: center
      justify-content: space-between

  &__window
    display: flex
    flex: 1

    ::v-deep .v-window__container
      width: 100%

  &__window-item
    height: 100%
    padding: 0 24px
    overflow-y: auto

  &__form
    &-status
      max-height: 330px

    &-bot-user
      &-empty
        text-align: center

  &__help-text
    font-size: 13px
    line-height: 20px
    color: #8B90A0
    margin-bottom: 16px

  &__actions
    display: flex
    padding: 14px 24px 24px 24px
    background: #F5F5F9
    margin-top: 2px

  &__errors
    display: flex
    align-items: flex-start
    justify-content: center
    padding: 0 10px

    & span
      font-size: 14px
      color: #666699
      margin-left: 10px
</style>
