<template>
  <router-link
    v-ripple="{ class: 'app-ripple' }"
    class="IntegrationListRow Row"
    :class="{ 'Row--disabled': integration.isDraft }"
    :to="editIntegrationPath"
    replace
  >
    <v-icon
      class="Row__icon"
      v-text="integration.integrationType.assets.icon"
    />
    <span
      class="Row__name"
      v-text="integration.name"
    />
    <span
      class="Row__note text--secondary"
      v-text="integration.isDraft ? $t('integration.draft') : ''"
    />
  </router-link>
</template>

<script>
export default {
  name: 'IntegrationListRow',

  props: {
    projectId: { type: String, required: true },
    integration: { type: Object, required: true },
  },

  computed: {
    editIntegrationPath() {
      const { integration: { id }, projectId } = this
      return {
        name: 'Integration',
        params: { projectId },
        query: { dialog: 'integration', integration: id },
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.Row
  $self: &

  display: flex
  height: 48px
  flex-wrap: nowrap
  align-items: center
  color: inherit
  text-decoration: none
  padding: 0 16px

  &:hover, &:active, &:focus
    background: rgba(229, 229, 255, .3)

  &--disabled
    #{$self}__icon, #{$self}__name, #{$self}__note
      filter: opacity(50%)

  &__icon
    margin-right: 4px

  &__name
    margin-right: 8px
</style>
