<template>
  <v-data-table
    class="IntegrationServersTable"
    :headers="headers"
    :items="integrationServers || []"
    :items-per-page="-1"
    :loading="integrationServers == null"
    item-key="id"
    fixed-header
    hide-default-footer
    must-sort
    :sort-by.sync="sortModel"
  >
    <template #item="{ item: server }">
      <IntegrationServersTableRow :server="server" />
    </template>
  </v-data-table>
</template>

<script>
import IntegrationServersTableRow from './IntegrationServersTableRow'

export default {
  name: 'IntegrationServersTable',

  components: {
    IntegrationServersTableRow,
  },

  props: {
    integrationServers: { type: Array, default: null },
  },

  data: () => ({
    sortModel: 'hostname',
  }),

  computed: {
    headers() {
      return [
        { text: this.$t('integration.TableHostname'), value: 'hostname' },
        { text: this.$t('integration.TableName'), value: 'name' },
        { text: this.$t('integration.TableWebhook'), value: 'webhook' },
        { text: this.$t('integration.TableCreated'), value: 'created' },

        // Actions column
        {
          value: '_actions',
          sortable: false,
          align: 'end',
        },
      ]
    },
  },
}
</script>

<style lang="sass" scoped>
.IntegrationServersTable
  border-radius: 8px
  box-shadow: 0 1px 2px rgba(0, 0, 0, .2)

  ::v-deep
    .v-data-table__wrapper
      border-radius: 8px
    tr > th
      white-space: nowrap

      &:first-child
        padding-left: 24px

    tr > th, tr > td
      white-space: nowrap
      &:last-child
        padding: 0 16px 0 0
</style>
